<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo d-flex justify-content-center align-items-center" :to="{ path: '/' }">
      <h2
        class="brand-text mb-0 d-none d-md-block"
        :class="{
          'text-white': skin === 'dark',
          'text-primary': skin !== 'dark',
        }"
      >
        KombiKlimaParca
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t("ComingSoonTitle") }}
        </h2>
        <p class="mb-3">
          {{ $t("ComingSoonMessage") }}
        </p>

        <b-button variant="primary" class="btn-md" @click="backHome()">
          {{ $t("BackToHome") }}
        </b-button>

        <b-img fluid :src="imgUrl" alt="Coming soon page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/coming-soon.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    skin() {
      return store.state.appConfig.layout.skin;
    },
  },
  created() {
    this.$i18n.locale = localStorage.bilgeCodeLocale;
    document.title = `KombiKlimaParca - ${this.$t(this.$route.meta.title)}`;
  },
  methods: {
    backHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
